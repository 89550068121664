import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingController, NavParams, Platform } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ModalService } from '../../component/modal/modal.service';
import { CrudService } from '../../services/crud.service';
import { UtilService } from '../../services/util.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { THEME_SETTING } from '../../utils/config';

@Component({
    selector: 'room-service-modal',
    templateUrl: 'room-service.html',
    styleUrls: ['room-service.scss'],
    providers: [InAppBrowser]
})
export class RoomServiceModal implements OnInit, OnDestroy {
    public dialerThemeSetting = THEME_SETTING;
    isIframeLoading = false;
    propertyId: string = ''
    room: string = ''
    buttons: any = []
    html: any = '';
    newHtml: any = '';
    hasUrl: boolean = false;
    maxHeight = (this._platform.height() * 0.6) + 'px';
    maxImgHeight = (this._platform.height() * 0.2) + 'px';
    selectedButton: any = {};
    callButton: any;
    scriptElements: HTMLScriptElement[] = [];
    public showCopyIcon: boolean = false
    public loading;
    private propertyConfigSubscribe: Subscription = null;
    constructor(
        private modalCtrl: ModalController,
        private crudService: CrudService,
        private utilService: UtilService,
        private navParams: NavParams,
        private _platform: Platform,
        private _modalService: ModalService,
        private iab: InAppBrowser,
        private sanitizer: DomSanitizer,
        private loadingCtrl: LoadingController) {
        if (this.navParams.get("buttons")) {
            this.buttons = this.navParams.get("buttons");
        }

        if (this.navParams.get("callButton")) {
            this.callButton = this.navParams.get("callButton");
        }
    }

    async ngOnInit() {
        this.propertyConfigSubscribe = this.crudService.propertyConfig$.pipe(
            tap(async (propertyConfig) => {
                this.dialerThemeSetting = propertyConfig?.dialerThemeSetting?.value || this.dialerThemeSetting;
            })
        ).subscribe();
        window?.addEventListener('message', (event) => {
            if (event.data === 'onLeave') {
                this.dismiss();
            }
        });
        this.propertyId = localStorage.getItem('propertyId')
        this.room = localStorage.getItem('room');
        if (this.navParams.get("html")) {
            this.isIframeLoading = true;

            this.hasUrl = this.navParams.get("hasUrl");
            this.showCopyIcon = this.navParams.get("showCopyIcon");
            if (this.hasUrl) {
                this.newHtml = this.sanitizer.bypassSecurityTrustResourceUrl(this.navParams.get("html"));
            } else {
                this.newHtml = this.sanitizer.bypassSecurityTrustHtml(this.navParams.get("html"));
            }
            console.log('this.newHtml', this.newHtml, this.hasUrl)
            const scriptUrls = this.extractScriptUrls(this.navParams.get("html"));
            this.loadScripts(scriptUrls);
            if (scriptUrls.length) {
                const loading = await this.loadingCtrl.create({
                    message: 'Loading....',
                });
                loading.present();
                this.loading = loading;
            }
        }

    }

    ngOnDestroy() {
        this.scriptElements?.forEach(script => {
            if (script.parentNode) {
                script.parentNode.removeChild(script);
            }
        });
        this.scriptElements = [];
        this.html = '';
        this.loading?.dismiss();
        this.propertyConfigSubscribe?.unsubscribe();
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }

    async requestRoomService(roomServiceBtn: any) {
        this.selectedButton = roomServiceBtn;
        switch (roomServiceBtn.type) {
            case 'room-service-issue':
                this.roomServiceIssueType(roomServiceBtn)
                break;
            case 'room-service-quantity':
                this.roomServiceQuantityType(roomServiceBtn);
                break;
            case 'room-service':
                this.showConfirmation();
                break;
            case 'url':
                const url = roomServiceBtn?.options?.url.replace('{{ROOM}}', localStorage.getItem('room'));
                this.iab.create(url);
                break;
            default:
                break;
        }

    }

    async roomServiceType(roomServiceLabel: string) {
        try {
            this.utilService.showLoader();
            let requestBody = {
                request: roomServiceLabel,
                propertyId: this.propertyId,
                roomNumber: this.room,
                quantity: this.selectedButton?.selectedQuantity?.value || 0,
                comment: `${this.selectedButton?.selectedIssue ? `${this.selectedButton?.selectedIssue || ''} : ${this.selectedButton?.selectedNotes || ''}` : `${this.selectedButton?.selectedNotes || ''}`} `
            };
            let response = await this.crudService.saveData('room-service', requestBody).toPromise();
            this.utilService.hideLoader();
            this.utilService.showToast(`Request save successfully`);
        } catch (error) {
            this.utilService.hideLoader();
        }
    }

    async roomServiceQuantityType(btn: any) {
        this.selectedButton.modalId = 'room-service-quantity';
        this.selectedButton.roomServiceQuantityTypeOpen = true;
        this.selectedButton.selectOptions = Array.from({ length: btn?.options?.qtyLimit || 0 }, (_, index) => ({
            value: index + 1,
            label: `${index + 1}`
        }));
        this.selectedButton.selectedQuantity = this.selectedButton?.selectOptions?.length ? this.selectedButton.selectOptions[0] : 0;
        this._modalService.open('room-service-quantity');
    }

    async roomServiceIssueType(btn: any) {
        this.selectedButton.modalId = 'room-service-issue';
        this.selectedButton.selectedIssue = '';
        this.selectedButton.selectedNotes = '';
        this.selectedButton.roomServiceIssueTypeOpen = true;
        this.selectedButton.selectedIssue = this.selectedButton?.options?.inputOptions?.length ? this.selectedButton?.options?.inputOptions[0] : '';
        this._modalService.open('room-service-issue');
    }

    async showConfirmation() {
        this._modalService.open('confirmation-popup');
    }

    async onYes() {
        this._modalService.close('confirmation-popup');
        if (this.selectedButton.modalId)
            this._modalService.close(this.selectedButton.modalId);
        await this.roomServiceType(this.selectedButton.label);
    }

    onNo() {
        this._modalService.close('confirmation-popup');
    }

    extractScriptUrls(htmlString: string): string[] {
        const scriptUrls: string[] = [];
        const regex = /<script\b[^>]*src="([^"]*)"[^>]*>/gi;
        let match;
        while ((match = regex.exec(htmlString)) !== null) {
            scriptUrls.push(match[1]);
        }
        return scriptUrls;
    }

    loadScripts(urls: string[]) {
        urls.forEach(url => this.loadScript(url));
    }

    loadScript(url: string) {
        const cacheBustedUrl = `${url}?v=${new Date().getTime()}`;
        const script = document.createElement('script');
        script.src = cacheBustedUrl;
        script.async = true;
        script.onload = () => {
            // Call any global initialization function if needed
            if (typeof window['initializeWidget'] === 'function') {
                window['initializeWidget'](); // Replace with actual initialization function
            }
        };
        document.body.appendChild(script);
        this.scriptElements.push(script);
    }

    onIframeLoad() {
        this.isIframeLoading = false;
        this.loading?.dismiss();
        console.log('start')
    }

    copyUrl(newHtml) {
        navigator.clipboard.writeText(newHtml?.changingThisBreaksApplicationSecurity);
        this.utilService.showToast('Copied!!');
    }
}
